<template>
  <div class="pad">
    <div class="pad-box">
      <div class="pad-box-tab">
        <ul>
          <li>
            <router-link to="/admin/testconter/padContent/corporeityTest">
              <div class="physique-btn">
                <p class="china-title">体质测试</p>
                <p class="es-title">Physical Fitness Test</p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/admin/testconter/padContent/exerciseTest">
              <div class="exercise-btn">
                <p class="china-title">运动测试</p>
                <p class="es-title">Exercise Test</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="pad-box-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentComp: "corporeityTest",
      isActive: "1",
    };
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.pad {
  width: 1434px;
  background: #f1f1f1;
  margin: 0 auto;
  .pad-box {
    padding: 30px;
    background: #fff;
  }
}
.pad-box-tab {
  ul {
    display: flex;
    .physique-btn {
      display: block;
      width: 424px;
      height: 164px;
      background: url(../../../../assets/img/pda/Btn_TZCS_UnC.png) no-repeat;
      background-size: 100% 100%;
    }
    .exercise-btn {
      display: block;
      width: 424px;
      height: 164px;
      background: url(../../../../assets/img/pda/Btn_YDCS_UnC.png) no-repeat;
      background-size: 100% 100%;
    }
    .china-title {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      padding-top: 43px;
      padding-left: 43px;
    }
    .es-title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 30px;
      padding-left: 43px;
    }
    .physique-btn:hover {
      background: url(../../../../assets/img/pda/Btn_TZCS_Cho.png) no-repeat;
      background-size: 100% 100%;
      .china-title {
        color: #ffffff;
      }
      .es-title {
        color: #dfdfdf;
      }
    }
    .exercise-btn:hover {
      background: url(../../../../assets/img/pda/Btn_YDCS_Cho.png) no-repeat;
      background-size: 100% 100%;
      .china-title {
        color: #ffffff;
      }
      .es-title {
        color: #dfdfdf;
      }
    }
    .router-link-active {
      .physique-btn {
        background: url(../../../../assets/img/pda/Btn_TZCS_Cho.png) no-repeat;
        background-size: 100% 100%;
      }
      .exercise-btn {
        background: url(../../../../assets/img/pda/Btn_YDCS_Cho.png) no-repeat;
        background-size: 100% 100%;
      }

      .china-title {
        color: #ffffff;
      }
      .es-title {
        color: #dfdfdf;
      }
    }
  }
}
.pad-box-content {
  padding: 0 10px;
}
</style>